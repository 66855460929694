﻿// scss-compile-options: :style => :compressed

//************************************
//*               GENERAL             *
//************************************/

$bleuClair: #1423DC;
/*a6d7f1*/
$bleuClair2: darken($bleuClair, 10%);

$blanc: #ffffff;
$gris: #d5d5d5;

@mixin borderRadius($value) {
}

@mixin boxShadow($color) {
    box-shadow: 2px 2px 3px 2px $color;
}

@mixin LigthBoxShadow() {
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.025), inset 0 -1px rgba(0, 0, 0, 0.025);
}

@mixin degrade($first, $last) {
    background: lighten($last, 10%); //* Old browsers */
    filter: none; //* IE6 + */
}

@mixin degradeWithImage($first, $last, $url) {
    background: lighten($last, 10%) url($url) no-repeat 6px 50%; //* Old browsers */
    filter: none; //* IE6 + */
}

/*******************************/
/*  Header
/*******************************/
input#numAffaire {
    margin-top: 0px;
    background-color: white;
    border: 1px solid $bleuClair2;
    color: $bleuClair2;
}

ul.nav {
    li {
        a {
            color: white;

            &:hover {
                color: white;
            }
        }
    }
}

.topbar {
    position: relative;
    z-index: 8000;

    input {
        background-image: url('../../Content/assets/details.png');
        background-position: right center;
        background-repeat: no-repeat;
        padding-top: 5px;
    }
}

#container-container {
    position: relative;
    z-index: 1000;
}

.topbar div > ul a.menu:hover,
.nav a.menu:hover,
.topbar div > ul li.open .menu,
.nav li.open .menu,
.topbar div > ul .dropdown-toggle:hover,
.nav .dropdown-toggle:hover,
.topbar div > ul .dropdown.open .dropdown-toggle,
.nav .dropdown.open .dropdown-toggle {
    background: $bleuClair2;
}

.topbar div > ul .menu-dropdown,
.nav .menu-dropdown,
.topbar div > ul .dropdown-menu,
.nav .dropdown-menu {
    background-color: white;
}

.topbar div > ul .menu-dropdown a.menu.open,
.nav .menu-dropdown a.menu.open,
.topbar div > ul .dropdown-menu a.menu.open,
.nav .dropdown-menu a.menu.open,
.topbar div > ul .menu-dropdown .dropdown-toggle.open,
.nav .menu-dropdown .dropdown-toggle.open,
.topbar div > ul .dropdown-menu .dropdown-toggle.open,
.nav .dropdown-menu .dropdown-toggle.open {
    background: white;
}

.topbar div > ul .menu-dropdown li a:hover,
.nav .menu-dropdown li a:hover,
.topbar div > ul .dropdown-menu li a:hover,
.nav .dropdown-menu li a:hover {
    background-color: white;
    color: #dddddd;
}

.topbar div > ul .menu-dropdown .divider,
.nav .menu-dropdown .divider,
.topbar div > ul .dropdown-menu .divider,
.nav .dropdown-menu .divider {
    background-color: white;
    border-color: #dddddd;
}

a.dropdown-toggle {
    &:hover {
        background-color: $bleuClair2;
        color: white;
    }

    li {
        background-color: $bleuClair2;

        a {
            color: white;

            &:hover {
                color: white;
            }
        }
    }
}

p.pull-right {
    margin-top: 8px;
    color: white;

    a {
        color: white;

        &:hover {
            color: white;
        }
    }
}

.actions {
    clear: both;
}

/*******************************/
/*  Boutons
/*******************************/
.btIcon {
    /*  */
    // Pour que SASS définisse la classe de base, meme vide
    &.btn {
        @include degrade($blanc, $gris);
        color: #2A5685;
        background-position: 2px 50%;
        background-repeat: no-repeat;
        padding-left: 26px;
    }

    &.btn:hover {
        color: #2A5685;
        text-shadow: 0 1px 0 rgba(42, 86, 133, 0.25);
    }

    &.bigBtn {
        font-weight: bold;
        height: 64px;
        line-height: 64px;
        padding-left: 70px;
    }

    @import "icones";
}

/*vue affaires*/
.form-groupe {
    fieldset {
        .clearfix {
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
}

.ie7 {
    .form-groupe {
        fieldset {
            .legend {
                span {
                    margin-top: -25px;
                    margin-right: 5px;
                }
            }
        }
    }
}

.ajout-tranche-container {
    height: 40px;
}

hr {
    margin-top: 5px;
    margin-bottom: 5px;
}

a {
    line-height: 1.3em;
    height: 1.3em;
}

.pagination {
    a {
        height: 34px;
        line-height: 34px;
    }
}

.filigrane-non-concerne {
    color: #fff !important;
}

.cancel-non-concerne {
    margin-top: 0 !important;
}
